import React from 'react';
import PropTypes from 'prop-types';
import DoubleQuote from './images/double-quote.svg';

const TestimonialQuote = ({ testimonial, linkText }) => {
  return (
    <div className="testimonial-quote-container">
      <div className="testimonial-quote">
        <div>
          <DoubleQuote />
        </div>
        <div className="testimonial-content standard intro">
          {testimonial.customerQuote}
        </div>
        <div className="testimonial-divider"></div>
        <div className="testimonial-author caption">
          <span className="family-name">{testimonial.familyName}</span>
          <span className="bullet">•</span>
          <span className="location">{testimonial.location}</span>
        </div>
        <a tabIndex="-1" className="xsmall" href={testimonial.slug}>
          {linkText}
        </a>
      </div>
    </div>
  );
};

export default TestimonialQuote;

TestimonialQuote.propTypes = {
  testimonial: PropTypes.object,
  linkText: PropTypes.string,
};
