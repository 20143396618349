import PropTypes from 'prop-types';
import React from 'react';
import { CityLocationListStyles } from './CityLocationList.styled';
import CityLocationItem from './CityLocationItem';

const CityLocationList = ({
  cityLocations,
  indexHighlighted,
  autoCompleteMaxItems,
  onCloseClick,
  autoCompleteOnSelection,
}) => {
  const onSelection = (ev, loc) => {
    ev.preventDefault();
    ev.stopPropagation();

    autoCompleteOnSelection(loc);

    if (onCloseClick) {
      onCloseClick(ev, false);
    }
  };

  let cities = cityLocations
    ? cityLocations.slice(0, autoCompleteMaxItems)
    : [];

  let cityNodes =
    cities.length > 0
      ? cities.map((loc, i) => {
          let highlighted = indexHighlighted === i;

          return (
            <CityLocationItem
              city={loc.city}
              state={loc.state}
              postalCode={loc.postalCode}
              highlighted={highlighted}
              key={`${loc.city}-${loc.state}-${i}`}
              onSelection={(ev) => {
                onSelection(ev, loc);
              }}
            />
          );
        })
      : null;

  let result =
    cityNodes && cityNodes.length > 0 ? (
      <div className="city-search-results">
        <div className="city-models-list">
          <ul>{cityNodes}</ul>
        </div>
      </div>
    ) : null;

  return (
    <CityLocationListStyles className="city-location-container">
      {result}
    </CityLocationListStyles>
  );
};

CityLocationList.propTypes = {
  autoCompleteMaxItems: PropTypes.any,
  autoCompleteOnSelection: PropTypes.func,
  cityLocations: PropTypes.array,
  indexHighlighted: PropTypes.any,
  onCloseClick: PropTypes.func,
};

export default CityLocationList;
