import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';
import { OverviewStyles } from './Overview.styled';
import Markdown from '@components/Shared/Markdown/Markdown';

const Overview = ({ overviewHeader, overviewText }) => (
  <OverviewStyles>
    <div className="hero-blurb">
      <Image
        alt="left-pattern"
        src="/public-assets/images/pattern-left.png"
        className="left-pattern"
        width={572}
        height={400}
      />
      <Image
        alt="right-pattern"
        src="/public-assets/images/pattern-right.png"
        className="right-pattern"
        width={486}
        height={395}
      />
      <div className="hero-blurb-text">
        <h2>
          {overviewHeader}
          <div className="hero-blurb-line">line</div>
        </h2>
        <div className="intro">
          <Markdown markdown={overviewText} />
          <div className="intro-link-text">
            <Link href="/ebuilt/">Learn More</Link>
          </div>
        </div>
      </div>
    </div>
  </OverviewStyles>
);

export default Overview;

Overview.propTypes = {
  overviewHeader: PropTypes.string,
  overviewText: PropTypes.string,
};
