import PropTypes from 'prop-types';
import React from 'react';

const CityLocationItem = ({
  city,
  state,
  postalCode,
  onSelection,
  highlighted = false,
}) => {
  let classNames = 'city-location';
  if (highlighted) {
    classNames += ' city-selected';
  }

  return (
    <li className={classNames}>
      <a onClick={onSelection}>
        {city}, {state} {postalCode}
      </a>
    </li>
  );
};

CityLocationItem.propTypes = {
  city: PropTypes.any,
  highlighted: PropTypes.bool,
  onSelection: PropTypes.any,
  postalCode: PropTypes.any,
  state: PropTypes.any,
};

export default CityLocationItem;
