// We need to add a parameter option to Matterport virtual tour URLs.
// There could be other types of URLs (e.g. momento360) used for virtual tours.
export const formatVirtTourRef = (virtualTourReference) => {
  if (virtualTourReference.includes('matterport')) {
    // If a Matterport virtual tour, add auto play option.
    // Otherwise Matterport opens in new window on mobile and you can't close the tour modal.
    // see https://support.matterport.com/s/article/Mobile-Open-Models-in-a-New-Browser-Tab?language=en_US
    return virtualTourReference + '&play=1';
  }
  return virtualTourReference;
};
