import styled from 'styled-components';
import { desktop_breakpoint_extended } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors.js';

export const OverviewStyles = styled.div`
  width: 100%;

  .hero-blurb-text {
    margin: auto;
    max-width: 500px;
    width: 86%;
  }

  .hero-blurb {
    background-color: #0080c6;

    display: flex;
    flex-direction: column;
    padding: 50px 0;
    position: relative;

    h2 {
      margin-bottom: 25px;
    }

    h2,
    .intro,
    .intro-link-text a {
      color: ${Colors.primary.white.standard};
    }
    img {
      display: none;
    }

    .hero-blurb-line {
      display: none;
    }

    .intro-link-text {
      margin-top: 20px;
      text-align: end;
      font-size: 20px;
    }

    .markdown {
      p,
      a {
        color: ${Colors.primary.white.standard};
        font-size: 18px;
        line-height: 1.61;
      }
    }
  }

  @media screen and (min-width: ${desktop_breakpoint_extended}px) {
    margin-bottom: 75px;

    .hero-blurb-text {
      max-width: none;
      justify-content: space-between;
    }

    .hero-blurb {
      height: 340px;

      img {
        display: block;
        position: absolute;
        max-width: 100%;
        height: auto;
      }

      .left-pattern {
        bottom: 0;
        left: 0;
      }

      .right-pattern {
        top: 0;
        right: 0;
      }

      .hero-blurb-line {
        border-top: 1px solid ${Colors.primary.white.standard};
        display: block;
        font-size: 0;
        margin-top: 50px;
        width: 50px;
      }
    }

    .hero-blurb-text {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      position: relative;

      h2 {
        width: 400px;
      }
      .intro {
        width: 50%;
      }

      h2,
      .intro {
        position: relative;
      }
      .intro-link-text {
        font-size: 22px;
      }
    }
  }

  @media screen and (min-width: 1080px) {
    .hero-blurb-text {
      .intro {
        left: -75px;
      }
    }
  }
`;
