import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';

const LazyLoad = ({ children, classNamePrefix, offset = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: `${offset}px 0px`,
  });

  return (
    <div
      className={classNamePrefix ? `${classNamePrefix}-wrapper` : null}
      ref={ref}
    >
      {inView ? children : null}
    </div>
  );
};

LazyLoad.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  classNamePrefix: PropTypes.string,
  offset: PropTypes.number,
};

export default LazyLoad;
