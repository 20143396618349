import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import TextField from '@components/Shared/TextField';

import { getLocationsFromSearch } from '@services/locationService';
import CityLocationList from '../CityLocationList/CityLocationList';
import {
  AUTOCOMPLETE_SEARCH_MAX_LIST,
  AUTOCOMPLETE_SEARCH_MIN_CHARS,
} from '@utils/config';

const LocationTextBox = ({
  locationKey,
  locationName = null,
  placeholderText = 'ZIP Code',
  postalCode,
  formErrors = null,
  onPostalCodeChange = null,
  focusOnLoad = true,
  finishSelection = null,
  onApplyButtonClick = null,
  textFieldStyle = 'compact',
}) => {
  //For Postal Code
  const textRef = useRef(null);

  const [lastSearch, setLastSearch] = useState('');
  const [cityResults, setCityResults] = useState(null);

  //Starting selected index at -1 to not have it select anything on dropdown reveal.
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const autoCompleteSearch = () => {
    const search = textRef.current.value;
    setLastSearch(search);

    //setCityResults(exampleCities);
    getLocationsFromSearch(search).then((results) => {
      setCityResults(results);
    });
  };

  const onKeyUp = (ev, value) => {
    //Get the Char Code for the selected state. We are doing this instead of ev.keyCode because of
    //how android virtual keyboards sometime act in situations, such as sending over a processing
    //key code every time. (KeyCode 229)
    let keyCode = ev.target.value
      .charAt(ev.target.selectionStart - 1)
      .charCodeAt();
    let inputValue = textRef.current.value;
    var eventKeyCode = String.fromCharCode(ev.keyCode);

    //Check if the value is a letter or number or if the value has changed. If the value hasn't changed,
    //let's ignore it!
    if (
      ev.keyCode === 8 ||
      (ev.keyCode === 46 && lastSearch.length !== inputValue.length) ||
      ((typeof inputValue === 'undefined' || value !== inputValue) &&
        //Check if a valid character was typed.
        /[a-zA-Z0-9-_ \b]/.test(eventKeyCode) &&
        ((keyCode >= 48 && keyCode <= 57) ||
          (keyCode >= 65 && keyCode <= 90) ||
          (keyCode >= 97 && keyCode <= 122) ||
          keyCode === 32 ||
          keyCode === 189))
    ) {
      if (
        isNaN(inputValue) &&
        inputValue.length >= AUTOCOMPLETE_SEARCH_MIN_CHARS
      ) {
        autoCompleteSearch();
      } else if (
        inputValue.length < AUTOCOMPLETE_SEARCH_MAX_LIST &&
        cityResults !== null
      ) {
        clearSearch();
      }
    }
  };

  const onKeyDown = (ev) => {
    let location = textRef.current.value.trim();

    if (ev.keyCode === 13 && cityResults !== null) {
      let isCity = isNaN(location);
      let useIndex = selectedIndex !== -1 ? selectedIndex : 0;
      let selectedItem = cityResults[useIndex];

      if (isCity && selectedItem) {
        let newPostalCode = selectedItem.postalCode;
        textRef.current.value = newPostalCode;

        if (onPostalCodeChange !== null) {
          onPostalCodeChange(newPostalCode);
        }

        if (finishSelection !== null) {
          finishSelection();
        }

        setCityResults(null);
        setSelectedIndex(-1);
      }
    } else if (
      ev.keyCode === 13 &&
      cityResults === null &&
      onApplyButtonClick !== null
    ) {
      onApplyButtonClick();
    }

    //Arrow Up
    if (ev.keyCode === 38) {
      moveIndex(-1);
    }

    //Arrow Down
    if (ev.keyCode === 40) {
      moveIndex(1);
    }
  };

  const moveIndex = (i) => {
    if (cityResults !== null) {
      let temp = selectedIndex + i;

      if (temp < 0) {
        temp = 0;
      } else if (
        temp >= cityResults.length ||
        temp >= AUTOCOMPLETE_SEARCH_MAX_LIST
      ) {
        let listIndex =
          cityResults.length < AUTOCOMPLETE_SEARCH_MAX_LIST
            ? cityResults.length
            : AUTOCOMPLETE_SEARCH_MAX_LIST;
        temp = listIndex - 1;
      }

      setSelectedIndex(temp);
    }
  };

  const clearSearch = () => {
    setCityResults(null);
    setSelectedIndex(-1);
  };

  useEffect(() => {
    if (focusOnLoad) {
      textRef.current.focus();
    }
  }, []);

  let cityText =
    cityResults !== null && cityResults.length > 0 ? (
      <CityLocationList
        //filter={this.input.value}
        onCloseClick={clearSearch}
        indexHighlighted={selectedIndex}
        autoCompleteOnSelection={(loc) => {
          textRef.current.value = loc.postalCode;
          if (onPostalCodeChange !== null) {
            onPostalCodeChange(loc.postalCode);
          }

          if (finishSelection !== null) {
            finishSelection();
          }

          clearSearch();
        }}
        cityLocations={cityResults}
        autoCompleteMaxItems={AUTOCOMPLETE_SEARCH_MAX_LIST}
      />
    ) : null;

  return (
    <div>
      <TextField
        id={locationName !== null ? `${locationName}` : `${locationKey}Zip`}
        defaultValue={postalCode}
        errorMessage={formErrors !== null ? formErrors.zip : null}
        label={placeholderText}
        automationId="zip-textfield"
        textFieldStyle={textFieldStyle}
        ref={textRef}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onChange={() => {
          if (onPostalCodeChange !== null) {
            onPostalCodeChange(textRef.current.value.trim());
          }
        }}
        onFocus={() => {
          if (isNaN(textRef.current.value.trim())) {
            autoCompleteSearch();
          }
        }}
        onBlur={() => {
          //clearSearch();
        }}
      />
      {cityText}
    </div>
  );
};

LocationTextBox.propTypes = {
  finishSelection: PropTypes.func,
  focusOnLoad: PropTypes.bool,
  formErrors: PropTypes.shape({
    zip: PropTypes.any,
  }),
  locationKey: PropTypes.any,
  locationName: PropTypes.any,
  onApplyButtonClick: PropTypes.func,
  onPostalCodeChange: PropTypes.func,
  placeholderText: PropTypes.string,
  postalCode: PropTypes.any,
  textFieldStyle: PropTypes.string,
};

export default LocationTextBox;
