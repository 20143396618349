import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import chZindex from '../../zIndex';

export const CityLocationListStyles = styled.div`
  position: absolute;
  z-index: ${chZindex.scrollToTopButton};
  background-color: white;
  border: 1px solid ${Colors.accent.grey5.standard};

  .city-search-results {
    position: relative;
    width: 235px;
    ul {
      padding-inline-start: 0;
      list-style-type: none;

      li {
        background-color: white;
        font-size: 12px;
        border-bottom: 1px solid ${Colors.accent.grey5.standard};

        line-height: 20px;
        height: auto;
        width: 100%;
        text-align: left;
        letter-spacing: normal;
        text-transform: none;

        a {
          padding: 5px 15px;
          cursor: pointer;
          display: block;
        }
      }

      .city-selected {
        background-color: ${Colors.accent.darkBlue.standard};
        a {
          color: #fff;
        }
      }
    }
  }
`;
