import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Button from '@components/Shared/Buttons/Button';
import Slideshow from '../../Shared/Slideshow/Slideshow';
import StArrowLeftSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/st-arrow-left.svg';
import StArrowRightSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/st-arrow-right.svg';
import AspectImage from '@components/Shared/AspectImage/AspectImage';
import TestimonialQuote from './TestimonialQuote';
import { formatHttpsUrl } from '@utils/utils';

const getTestimonials = (
  customerTestimonials,
  linkText = '',
  fullWidth = false
) => {
  // I hate doing this, but the carousel wants to basically eat my tabbing and I have to kill it so we can manage the tabbing
  // ourselves
  setTimeout(() => {
    if (document.querySelectorAll('.carousel-root').length > 0) {
      document.querySelectorAll('.carousel-root')[0].tabIndex = -1;
    }
  }, 1);

  return customerTestimonials.map((testimonial, index) => {
    return (
      <div key={`testimonial-${index}`} className="testimonial">
        {fullWidth ? (
          <AspectImage
            behavior="fill-container"
            containerOrientation="landscape"
            imageOrientation="landscape"
            className="ankle-cta-image"
            alt={
              testimonial.customerImage.description ||
              testimonial.customerImage.title
            }
            aspectRatio={{ width: 2880, height: 1260 }}
            src={formatHttpsUrl(
              `${testimonial.fullWidthImage?.file.url}?q=85&w=1600&fm=webp`
            )}
          />
        ) : (
          <Image
            alt={
              testimonial.customerImage.description ||
              testimonial.customerImage.title
            }
            src={formatHttpsUrl(
              `${testimonial.customerImage?.file.url}?q=85&w=808&fm=webp`
            )}
            width={924}
            height={720}
          />
        )}
        <TestimonialQuote testimonial={testimonial} linkText={linkText} />
      </div>
    );
  });
};

const Testimonials = ({ sectionHeader, testimonials, linkText, fullWidth }) => {
  const router = useRouter();
  const [currentSlide, setCurrentSlide] = useState(0);

  const prevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(testimonials.length - 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const nextSlide = () => {
    if (currentSlide === testimonials.length - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  return (
    <div className="testimonials-container">
      {!fullWidth && <h2>{sectionHeader}</h2>}
      <div className="testimonials">
        <div
          tabIndex="0"
          className="focus-grabber"
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              router.push(testimonials[currentSlide].studioPostSlug);
            }
          }}
        >
          <Slideshow
            axis="horizontal"
            swipeScrollTolerance={75}
            preventMovementUntilSwipeScrollTolerance={true}
            tabIndex="-1"
            transitionTime={200}
            showThumbs={false}
            selectedItem={currentSlide}
            imageArray={getTestimonials(testimonials, linkText, fullWidth)}
            onChange={(index) => {
              setCurrentSlide(index);
            }}
          />
        </div>
        <div className="testimonial-controls">
          <Button
            className="gtm-testimonial-controls"
            isCompact={true}
            type="button"
            onClick={prevSlide}
            automationId="testimonial-prev-slide-button"
          >
            <div>
              <StArrowLeftSvg />
            </div>
          </Button>
          <span className="testimonial-indicator xsmall">
            {currentSlide + 1} / {testimonials.length}
          </span>
          <Button
            className="gtm-testimonial-controls"
            isCompact={true}
            type="button"
            onClick={nextSlide}
            automationId="testimonial-next-slide-button"
          >
            <div>
              <StArrowRightSvg />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

Testimonials.propTypes = {
  sectionHeader: PropTypes.string,
  testimonials: PropTypes.array,
  linkText: PropTypes.string,
  fullWidth: PropTypes.bool,
};
