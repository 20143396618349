import styled from 'styled-components';
import { desktop_breakpoint_extended } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const HomeStyles = styled.div`
  /* For the home page only, override the acumin-pro font from Smokey. */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
  }

  @media screen and (min-width: ${desktop_breakpoint_extended}px) {
    display: flex;
    flex-direction: column;

    .home-hero {
      order: 1;
    }

    .overview-wrapper,
    .overview-section {
      order: 2;
    }

    .featured-homes-wrapper,
    .featured-homes-section {
      order: 3;
    }

    .home-cta-wrapper {
      order: 4;
    }

    .how-it-works-wrapper {
      order: 5;
    }

    .testimonials-wrapper {
      order: 6;
    }

    .image-grid-wrapper {
      order: 7;
    }

    .home-ankle-cta-wrapper {
      order: 8;
    }

    .home-form-wrapper {
      order: 9;
    }
  }
`;

export default HomeStyles;
