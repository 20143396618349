import React from 'react';
import PropTypes from 'prop-types';
import { extractColors } from '@utils/utils';
import Testimonials from './Testimonials';
import {
  TestimonialsStyles,
  TestimonialsFullWidthStyles,
} from './Testimonials.styled';

const TESTIMONIAL_SECTION_BACKGROUND = 'Common Testimonial Section Background';

const Wrapper = ({ bgColor, standardWidth, children, backgroundImage }) => {
  return (
    <div className="home-testimonials">
      {standardWidth ? (
        <TestimonialsStyles
          backgroundColor={bgColor}
          backgroundImage={backgroundImage}
        >
          {children}
        </TestimonialsStyles>
      ) : (
        <TestimonialsFullWidthStyles>{children}</TestimonialsFullWidthStyles>
      )}
    </div>
  );
};

Wrapper.propTypes = {
  bgColor: PropTypes.string,
  standardWidth: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  backgroundImage: PropTypes.string,
};

const TestimonialsContainer = ({
  sectionStyle,
  sectionHeader,
  testimonials,
  linkText,
  backgroundColor,
  isMobile,
  backgroundImage,
}) => {
  const bgColor = extractColors(backgroundColor);
  const standardWidth =
    sectionStyle === TESTIMONIAL_SECTION_BACKGROUND || isMobile;

  return (
    <Wrapper
      bgColor={bgColor[0]}
      standardWidth={standardWidth}
      backgroundImage={backgroundImage}
    >
      <Testimonials
        sectionHeader={sectionHeader}
        testimonials={testimonials}
        linkText={linkText}
        fullWidth={!standardWidth}
      />
    </Wrapper>
  );
};

export default TestimonialsContainer;

TestimonialsContainer.propTypes = {
  sectionStyle: PropTypes.string,
  sectionHeader: PropTypes.string,
  testimonials: PropTypes.array,
  linkText: PropTypes.string,
  backgroundColor: PropTypes.string,
  isMobile: PropTypes.bool,
  backgroundImage: PropTypes.string,
};
