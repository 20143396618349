import styled from 'styled-components';
import {
  desktop_breakpoint,
  desktop_breakpoint_extended,
  tablet_breakpoint,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors.js';

export const AnkleCTAStyles = styled.div`
  margin: auto;
  min-height: 450px;
  position: relative;
  overflow: hidden;

  .ankle-cta-image {
    min-height: 500px;
    transform: scale(1.5);
    left: 200px;
    bottom: 100px;

    img {
      object-fit: cover;
      transform: none;
    }
  }

  .ankle-cta-content-container {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: ${(props) => (props?.align ? props.align : 'flex-start')};
  }

  .ankle-cta-content {
    margin: 3vw 4vw;
    width: 400px;
    height: 500px;
    background: ${Colors.primary.white.standard};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    .ankle-searchcard-header {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      margin: 30px;
      text-align: center;
      color: #25292d;
    }

    .animated {
      min-height: 42px;
      overflow: hidden;
      flex-direction: column-reverse;

      input:valid + label {
        font-size: 16px;
        animation: none;
      }

      input:focus + label {
        font-size: 16px;
        animation: none;
      }

      input + label {
        font-size: 16px;
        animation: none;
        top: 1px;
      }
    }
    .ankle-searchcard-field {
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 18px;
      box-sizing: border-box;
      border-radius: 2px;
      color: #4d5255;
      border: 1px solid #cacccf;

      input {
        height: 42px;
        color: #4d5255;
        border: none;
        text-align: end;
        padding: 0 16px;
      }

      input:focus {
        border: 1px solid #0075c9;
      }

      button:focus {
        border: 1px solid #0075c9;

        label {
          color: #0075c9;
        }
      }

      label {
        color: #4d5255;
        margin: auto;
        padding: 8px 12px;
        width: 100%;
      }

      button {
        border: none;
        color: #4d5255;
        cursor: pointer;
        display: flex;
        padding: 0;

        label {
          padding: 0 12px;
          color: #4d5255;
          font-size: 16px;
          top: 8px;
        }

        .dropdown-selected-text {
          padding: 0 10px;
          margin: auto;
          color: #4d5255;
          text-align: end;
        }
      }

      .dropdown-chevron {
        top: 14px !important;
      }

      .dropdown-display {
        li {
          color: #4d5255;
        }

        li.selected {
          color: #ffffff;

          svg {
            left: 2px;
          }
        }

        li:hover {
          color: #4d5255;
        }

        text-align: end;
      }
    }

    .ankle-searchcard-field:focus-within {
      border: 1px solid #0075c9;
    }

    .ankle-searchcard-field-price {
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 18px;

      .min-price {
        width: 100%;
        margin: 0px;
      }

      .max-price {
        width: 100%;
        margin: 0px;
      }

      .dropdown-display {
        max-height: 200px;
        color: #4d5255 !important;
      }

      .dropdown-chevron {
        top: 14px !important;
      }
    }

    .gtm-home-listings {
      display: table;
      margin: 30px auto;

      .webform-button {
        visibility: ${(props) => (props.submittingForm ? 'hidden' : 'visible')};
      }

      .sk-fading-circle {
        margin-top: ${(props) => (props.submittingForm ? '-24px' : '0px')};
      }
    }
  }

  a.transparent,
  button.transparent {
    border-color: #ffffff !important; /* !important is here to override a browser quirk with transparent border colors */
  }

  @media screen and (min-width: 1440px) {
    .ankle-cta-content {
      margin: 10% 4vw;
    }
  }

  @media screen and (min-width: 2560px) {
    .ankle-cta-content {
      margin: 18% 4vw;
    }
  }

  @media screen and (max-width: ${desktop_breakpoint_extended}px) {
    .ankle-searchcard-header {
      margin: 25px !important;
    }

    .gtm-home-listings {
      margin: 25px auto !important;
    }

    .ankle-cta-image {
      min-height: 550px;
      transform: scale(1.6);
      left: 200px;
      bottom: 100px;
    }

    .ankle-cta-content {
      width: 400px;
    }
  }

  @media screen and (max-width: ${desktop_breakpoint}px) {
    .ankle-cta-content {
      max-width: 350px;
      max-height: 435px;
      margin: 4vw;

      .ankle-searchcard-field {
        margin-bottom: 14px;

        button {
          padding: 0;

          label {
            line-height: 8px;
            position: initial;
          }

          .dropdown-selected-text {
            padding: 0 18px;
          }
        }
      }
    }

    .ankle-cta-image {
      min-height: 500px;
      transform: scale(2);
      left: 200px;
      bottom: 200px;
    }

    .ankle-searchcard-header {
      margin: 16px !important;
    }

    .gtm-home-listings {
      margin: 16px auto !important;
    }

    .ankle-searchcard-header {
      font-size: 20px !important;
    }

    .ankle-searchcard-field-price {
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 14px;
    }
    .ankle-cta-content {
      .ankle-searchcard-field-price {
        .dropdown-display {
          max-height: 280px;
        }

        .dropdown-selected-text {
          padding: 0 18px;
        }
      }
    }
  }

  @media screen and (max-width: 425px) {
    .ankle-cta-content {
      min-width: 275px;
      margin: 45px 50px;
      max-width: 325px;
      max-height: 410px;
    }

    .ankle-cta-image {
      left: 175px;
    }

    .ankle-searchcard-header {
      font-size: 18px;
    }

    .ankle-cta-content {
      .ankle-searchcard-field {
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 12px;

        button {
          font-size: 14px;
          .dropdown-selected-text {
            padding: 0px 18px;
          }
        }

        input {
          font-size: 14px;
        }

        label {
          font-size: 14px;
        }

        .dropdown-selected-text {
          padding: 0 18px;
        }
      }

      .animated {
        input:valid + label {
          font-size: 14px;
        }

        input:focus + label {
          font-size: 14px;
        }

        input + label {
          font-size: 14px;
        }
      }

      .ankle-searchcard-field-price {
        display: block;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 12px;

        .min-price {
          width: 100%;
          margin: 0;
          margin-bottom: 12px;
        }

        .max-price {
          width: 100%;
          margin: 0;
        }

        .dropdown-display {
          max-height: 280px;
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: ${tablet_breakpoint}px) {
    .ankle-cta-content {
      min-width: 200px;
      margin: 45px 30px;
      max-height: 410px;
    }

    .ankle-cta-image {
      left: 150px;
    }

    .ankle-searchcard-header {
      font-size: 18px;
    }

    .ankle-cta-content {
      .ankle-searchcard-field {
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 12px;

        button {
          font-size: 14px;

          .dropdown-selected-text {
            padding: 0 18px;
          }
        }

        input {
          font-size: 14px;
        }

        label {
          font-size: 14px;
        }
      }

      .animated {
        input:valid + label {
          font-size: 14px;
        }

        input:focus + label {
          font-size: 14px;
        }

        input + label {
          font-size: 14px;
        }
      }

      .ankle-searchcard-field-price {
        display: block;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 12px;

        .min-price {
          width: 100%;
          margin: 0;
          margin-bottom: 12px;
        }

        .max-price {
          width: 100%;
          margin: 0;
        }

        .dropdown-display {
          max-height: 280px;
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 280px) {
    .ankle-cta-image {
      left: 125px;
    }

    .ankle-cta-content {
      margin: 45px 30px;
      max-height: 410px;

      .animated {
        input:valid + label {
          font-size: 14px;
        }

        input:focus + label {
          font-size: 14px;
        }

        input + label {
          font-size: 14px;
        }
      }
    }

    .dropdown-selected-text {
      padding: 0 18px;
    }
  }
`;
